/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { SkeletonUtils } from "three-stdlib";

export const FishModel = forwardRef((props, ref) => {
  const groupRef = useRef();

  const { scene } = useGLTF("/models/fish.glb");
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);

  //================= KEEP THIS SECTION WHEN REPLACING MODEL ===================
  useEffect(() => {
    // If depthWrite is false, then the stream water texture will always render
    // over the fish texture even if the fish's location puts it above the water.
    materials.MAT_fish_fin.depthWrite = true;

    groupRef.current.traverse(
      // Hide helper meshes
      (o) => (o.visible = !["Circle", "Sphere"].includes(o.name)),
    );
  }, []);
  //============================================================================

  return (
    <group ref={mergeRefs([groupRef, ref])} {...props}>
      <group name="Scene">
        <group name="fish_rig">
          <primitive object={nodes.root} />
        </group>
        <mesh
          name="Circle"
          castShadow
          receiveShadow
          geometry={nodes.Circle.geometry}
          material={materials.water_ripples}
          position={[-0.012, 0, 0]}
        />
        <mesh
          name="Sphere"
          geometry={nodes.Sphere.geometry}
          material={nodes.Sphere.material}
          position={[-0.008, -0.241, 0.175]}
          scale={0.044}
        />
        <group name="GEO_fish">
          <skinnedMesh
            name="Cube001"
            geometry={nodes.Cube001.geometry}
            material={materials.MAT_fish_fin}
            skeleton={nodes.Cube001.skeleton}
          />
          <skinnedMesh
            name="Cube001_1"
            geometry={nodes.Cube001_1.geometry}
            material={materials.MAT_fish_fin}
            skeleton={nodes.Cube001_1.skeleton}
          />
        </group>
        <skinnedMesh
          name="GEO_fish_eye"
          geometry={nodes.GEO_fish_eye.geometry}
          material={materials.MAT_fish_eye}
          skeleton={nodes.GEO_fish_eye.skeleton}
        />
      </group>
    </group>
  );
});

useGLTF.preload("/models/fish.glb");

FishModel.displayName = "Fish Model";

import PropTypes from "prop-types";
import { useMemo } from "react";

import { Tag } from "./tag";

import "./match-type.tag.scss";

export const MatchTypeTag = ({ type }) => {
  const typeText = useMemo(() => {
    switch (type) {
      case "exact": {
        return "my choice";
      }
      case "related": {
        return "close match";
      }
    }
  });

  return <Tag className="tx-match-type-tag" value={<span>{typeText}</span>} />;
};

MatchTypeTag.propTypes = {
  type: PropTypes.oneOf(["exact", "related"]),
};

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRotateLeft,
  faArrowUp,
  faBan,
  faCircleDollar,
  faCircleInfo,
  faDoorOpen,
  faLock,
  faLockKeyhole,
  faMapPin,
  faPaperPlane,
  faStar,
  faStars,
  faThumbsUp,
  faVrCardboard,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";

export const initRegularIcons = () => {
  library.add(
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowRotateLeft,
    faArrowUp,
    faBan,
    faCircleDollar,
    faCircleInfo,
    faDoorOpen,
    faLock,
    faLockKeyhole,
    faMapPin,
    faPaperPlane,
    faStar,
    faStars,
    faThumbsUp,
    faVrCardboard,
    faXmark,
  );
};

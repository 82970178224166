import { useEffect } from "react";

export const useVisibilityCheck = ({
  ref,
  initialRenderOnly = false,
  onVisibilityCheck,
  threshold = 0.5,
}) => {
  useEffect(() => {
    let observer;
    // using requestAnimationFrame since it won't fire until the component has
    // actually rendered -- without this we'd need some other way to know
    // whether the "false" we get for the intersection check is because it
    // really isn't visible or because React just hasn't finished rendering
    // everything yet.
    const requestId = requestAnimationFrame(() => {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (initialRenderOnly) {
            // we only care if it's visible on initial render, so we can stop
            // observing now
            observer.disconnect();
          }
          onVisibilityCheck(entry.isIntersecting);
        },
        { threshold },
      );

      observer.observe(ref.current);
    });

    return () => {
      cancelAnimationFrame(requestId);
      observer?.disconnect();
    };
  }, [initialRenderOnly, onVisibilityCheck, ref]);
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { buttonTracking } from "@lib/tracking.helpers";

import "./toast.scss";

export const ToastType = {
  error: "error",
  warning: "warning",
  success: "success",
  info: "info",
};

const getToastIcon = (type) => {
  switch (type) {
    case ToastType.error: {
      return ["fa-solid", "fa-triangle-exclamation"];
    }
    case ToastType.success: {
      return ["fa-solid", "fa-check"];
    }
    case ToastType.warning: {
      return ["fa-solid", "siren-on"];
    }
    case ToastType.info: {
      return ["fa-solid", "person-circle-question"];
    }
  }
};

export const Toast = ({
  open,
  type,
  onClose,
  closable,
  message,
  className,
}) => {
  const icon = useMemo(() => getToastIcon(type));

  const onCloseHandler = (event) => {
    event.preventDefault();
    onClose?.();
  };

  return (
    <AnimatePresence mode="popLayout">
      {open && (
        <motion.div
          layout
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className={clsx("tx-toast", type, className)}
        >
          <div className="icon-container">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div
            className={clsx("text-container", closable && "has-closer-button")}
          >
            {message}
          </div>
          {closable && (
            <button
              className="close-container"
              onClick={onCloseHandler}
              {...buttonTracking("toast", "close")}
            >
              <FontAwesomeIcon icon={["fa-regular", "xmark"]} />
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(["error", "warning", "success", "info"]),
  message: PropTypes.node,
  closable: PropTypes.bool,
  className: PropTypes.string,
};

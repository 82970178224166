import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowTrendDown,
  faArrowTrendUp,
  faBan,
  faCheck,
  faCheckCircle,
  faCircleInfo,
  faClock,
  faPersonCircleQuestion,
  faStar,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

export const initSolidIcons = () => {
  library.add(
    faArrowTrendDown,
    faArrowTrendUp,
    faBan,
    faCheck,
    faCheckCircle,
    faCircleInfo,
    faClock,
    faPersonCircleQuestion,
    faStar,
    faTriangleExclamation,
  );
};

import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useUserPreliminaryCareersOptions = ({ includeRelatedCareers }) => {
  const queryFn = useApiRequest({
    url: "/users/me/preliminary-careers",
    method: HttpMethod.Get,
    params: { include_related_careers: includeRelatedCareers },
  });

  return {
    queryKey: ["user-preliminary-careers"],
    queryFn,
    refetchOnMount: "always",
  };
};

export const useUserPreliminaryCareers = ({ includeRelatedCareers } = {}) => {
  return useQuery(useUserPreliminaryCareersOptions({ includeRelatedCareers }));
};

/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { forwardRef, useEffect, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import { Box3, LoopOnce, Vector3 } from "three";

import { FishModel } from "./fish.model";

import { randFloat } from "three/src/math/MathUtils";

const BOUNDS_ROTATION = Math.PI * 0.25;
const MAX_RADIANS = Math.PI * 2;

const ANIMATION_NAME = "ANIM_fish_jump-1";

const bounds = [
  new Box3(new Vector3(-80, 0, -150), new Vector3(40, 0, -90)),
  new Box3(new Vector3(-80, 0, -230), new Vector3(-40, 0, -180)),
];
// Since the bounds need to be rotated, we will translate the bounds to be
// centered at the origin so we can apply a rotation to our generated points and
// then translate the generated point to where the bounds are actually supposed
// to be.
const boundsCenter = bounds.map((b) => {
  // calculate center of bounds
  const center = b.max.clone().sub(b.min).divideScalar(2).add(b.min);
  // move bounds to be centered at origin
  b.min.sub(center);
  b.max.sub(center);
  // this will be used to move generated points back to where we want them
  return center;
});

export const RandomFish = forwardRef((props, ref) => {
  const groupRef = useRef();

  const [position, setPosition] = useState([0, -1000, 0]);
  const [rotationY, setRotationY] = useState(0);

  const { animations } = useGLTF("/models/fish.glb");
  const { actions } = useAnimations(animations, groupRef);

  useEffect(() => {
    const timeoutIds = [];

    const showFish = () => {
      // All timeouts should be complete at this point
      timeoutIds.length = 0;

      const index = Math.random() < 0.5 ? 0 : 1;
      const boundsToUse = bounds[index];

      // Grab a random position within the bounds
      const position = new Vector3(
        randFloat(boundsToUse.min.x, boundsToUse.max.x),
        randFloat(boundsToUse.min.y, boundsToUse.max.y),
        randFloat(boundsToUse.min.z, boundsToUse.max.z),
      );
      // Rotate position and then translate it to where it's supposed to be
      position
        .applyAxisAngle(new Vector3(0, 1, 0), BOUNDS_ROTATION)
        .add(boundsCenter[index]);
      setPosition(position);

      // Generate a random direction for the fish to face
      setRotationY(randFloat(0, MAX_RADIANS));

      // Play animation
      const action = actions[ANIMATION_NAME];
      action.setLoop(LoopOnce);
      action.reset().play();

      timeoutIds.push(
        // Move the fish out of sight when animation is done
        setTimeout(
          () => setPosition([0, -1000, 0]),
          action.getClip().duration * 1000,
        ),
        // Schedule a future fish jump
        setTimeout(showFish, randFloat(6000, 17_000)),
      );
    };

    timeoutIds.push(setTimeout(showFish, randFloat(2000, 5000)));

    return () => {
      for (const timeoutId of timeoutIds) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <FishModel
      ref={mergeRefs([groupRef, ref])}
      position={position}
      rotation-y={rotationY}
      {...props}
    />
  );
});

RandomFish.displayName = "Random Fish Model";

import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useSearchCareersQueryOptions = ({ term }) => {
  const queryFn = useApiRequest({
    url: "/careers",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["search-careers", term],
    queryFn: async ({ signal }) =>
      queryFn({
        params: { include_unsupported: true, title_search: term },
        signal,
      }),
    // Avoid search for empty string
    enabled: !!term,
  };
};

export const useSearchCareers = (term) => {
  return useQuery(useSearchCareersQueryOptions({ term }));
};

export const EmptyArray = (length) => Array.from({ length });

export const Delay = async (ms) => new Promise((_) => setTimeout(_, ms));

export const ShuffleArray = (array) => {
  for (let i = array?.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const roundToNearestIncrement = (
  num,
  increment,
  { roundingModifier = identity } = {},
) => Math.round(roundingModifier(num / increment)) * increment;

export const isValidEmail = (value) => {
  return /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i.test(value);
};

export const identity = (x) => x;
export const noop = () => {};

export const isNullish = (val) => (val ?? undefined) === undefined;

export const strToBool = (value) => value?.toLowerCase() === "true";

export const clamp = (lower, upper) => (value) =>
  Math.max(Math.min(value, upper), lower);

export const noPropagationHandler = (handler) => (event) => {
  event?.stopPropagation();
  handler?.(event);
};

import clsx from "clsx";
import PropTypes from "prop-types";

import "./skill.icon.scss";

const defaultImageUrl = `/images/skills/default.png`;

export const SkillIcon = ({ skill, className }) => {
  const { id, name, categoryId, subcategoryId } = skill;
  const skillImageUrl = `/images/skills/${id}.png`;
  const subcategoryImageUrl = `/images/skills/subcategories/${subcategoryId}.png`;
  const categoryImageUrl = `/images/skills/categories/${categoryId}.png`;

  const handleError = ({ target }) => {
    if (target.src.endsWith(skillImageUrl)) {
      // if not specefic icon then try subcategory icon
      target.src = subcategoryImageUrl;
    } else if (target.src.endsWith(subcategoryImageUrl)) {
      // If not subcategory icon then try category icon
      target.src = categoryImageUrl;
    } else {
      // if not category icon then go default icon - rare case
      target.src = defaultImageUrl;
    }
  };

  return (
    <div className={clsx("skill-icon-container", className)}>
      <img
        src={skillImageUrl}
        alt={`Skill icon for ${name}`}
        onError={handleError}
      />
      <span>{name}</span>
    </div>
  );
};

SkillIcon.displayName = "Skill Icon";

SkillIcon.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    categoryId: PropTypes.number,
    subcategoryId: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

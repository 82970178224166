/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { SkeletonUtils } from "three-stdlib";

const WearingOnAnimation = "POSE-fannyPack-on";

export function FannyPack({ ...props }) {
  const group = useRef();
  const { scene, animations } = useGLTF("/models/fanny-pack.glb");
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    const animation = actions[WearingOnAnimation];
    animation?.play();

    return () => animation?.stop();
  }, []);

  return (
    <group name="fanny_pack_object" ref={group} {...props}>
      <group name="Scene">
        <group name="fanny_pack-RIG">
          <primitive object={nodes.root} />
          <primitive object={nodes["MCH-pack_strap_start01parentL"]} />
          <primitive object={nodes["MCH-pack_strap_mid01parentL"]} />
          <primitive object={nodes["MCH-pack_strap_mid02parentL"]} />
          <primitive object={nodes["MCH-pack_strap_mid03parentL"]} />
          <primitive object={nodes["MCH-pack_strap_endparentL"]} />
          <primitive object={nodes["MCH-pack_strap_end02parentL"]} />
          <primitive object={nodes["MCH-pack_strap_006parentL001"]} />
          <primitive object={nodes["MCH-pack_strap_start01parentR"]} />
          <primitive object={nodes["MCH-pack_strap_mid01parentR"]} />
          <primitive object={nodes["MCH-pack_strap_mid02parentR"]} />
          <primitive object={nodes["MCH-pack_strap_mid03parentR"]} />
          <primitive object={nodes["MCH-pack_strap_endparentR"]} />
          <primitive object={nodes["MCH-pack_strap_end02parentR"]} />
          <primitive object={nodes["MCH-pack_strap_006parentR001"]} />
        </group>
        <group name="GEO_fanny_pack">
          <skinnedMesh
            name="Cube004"
            geometry={nodes.Cube004.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.Cube004.skeleton}
          />
          <skinnedMesh
            name="Cube004_1"
            geometry={nodes.Cube004_1.geometry}
            material={materials.PaletteMaterial002}
            skeleton={nodes.Cube004_1.skeleton}
          />
        </group>
        <skinnedMesh
          name="GEO_fanny_pack001"
          geometry={nodes.GEO_fanny_pack001.geometry}
          material={materials.PaletteMaterial003}
          skeleton={nodes.GEO_fanny_pack001.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_pack002"
          geometry={nodes.GEO_fanny_pack002.geometry}
          material={materials.PaletteMaterial003}
          skeleton={nodes.GEO_fanny_pack002.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_pack003"
          geometry={nodes.GEO_fanny_pack003.geometry}
          material={materials.PaletteMaterial004}
          skeleton={nodes.GEO_fanny_pack003.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_packstrapL"
          geometry={nodes.GEO_fanny_packstrapL.geometry}
          material={materials.PaletteMaterial002}
          skeleton={nodes.GEO_fanny_packstrapL.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_pack_zipper_pull_sml"
          geometry={nodes.GEO_fanny_pack_zipper_pull_sml.geometry}
          material={materials.PaletteMaterial005}
          skeleton={nodes.GEO_fanny_pack_zipper_pull_sml.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_pack_zipper_pull_sml001"
          geometry={nodes.GEO_fanny_pack_zipper_pull_sml001.geometry}
          material={materials.PaletteMaterial005}
          skeleton={nodes.GEO_fanny_pack_zipper_pull_sml001.skeleton}
        />
        <skinnedMesh
          name="GEO_fanny_pack_buckle-M"
          geometry={nodes["GEO_fanny_pack_buckle-M"].geometry}
          material={materials.PaletteMaterial006}
          skeleton={nodes["GEO_fanny_pack_buckle-M"].skeleton}
          morphTargetDictionary={
            nodes["GEO_fanny_pack_buckle-M"].morphTargetDictionary
          }
          morphTargetInfluences={
            nodes["GEO_fanny_pack_buckle-M"].morphTargetInfluences
          }
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/fanny-pack.glb");

FannyPack.propTypes = {
  animated: PropTypes.bool,
};

import { reduce, transition } from "robot3";

import { initialContext, SURVEY_STATES } from "./constants";

export const dataLoadedTransition = (to) =>
  transition(
    "dataLoaded",
    to,
    reduce((ctx, { property, data }) => ({
      ...ctx,
      [`${property}Loaded`]: true,
      [property]: data,
    })),
  );

export const startOverTransition = transition(
  "startOver",
  SURVEY_STATES.initializing,
  reduce(() => ({ ...initialContext, startedOver: true })),
);

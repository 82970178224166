import { useMachine } from "react-robot";
import {
  createMachine,
  guard,
  immediate,
  reduce,
  state,
  transition,
} from "robot3";

import { isNullish, ShuffleArray } from "@lib/app.helpers";
import {
  initialContext,
  NUM_INITIAL_QUESTIONS,
  SURVEY_STATES,
} from "./constants";
import {
  isInitialDataLoaded,
  isPrelimComplete,
  isRatingComplete,
  isSurveyComplete,
} from "./guards";
import {
  careerRatingsAnswerReducer,
  careerRatingsReducer,
  preliminaryCareersReducer,
  surveyAnswersReducer,
  surveyQuestionsReducer,
} from "./reducers";
import { dataLoadedTransition, startOverTransition } from "./transitions";

const machine = createMachine(
  {
    [SURVEY_STATES.initializing]: state(
      immediate(SURVEY_STATES.initialized, guard(isInitialDataLoaded)),
      dataLoadedTransition(SURVEY_STATES.initializing),
    ),
    [SURVEY_STATES.initialized]: state(
      immediate(SURVEY_STATES.complete, guard(isRatingComplete)),
      immediate(
        SURVEY_STATES.ratingCareers,
        guard((ctx) => ctx.careerMatchesFetched),
      ),
      immediate(
        SURVEY_STATES.loadingCareerMatches,
        guard(isPrelimComplete),
        guard(isSurveyComplete),
      ),
      immediate(
        SURVEY_STATES.survey,
        guard(isPrelimComplete),
        reduce((ctx) => {
          const surveyQuestions = ShuffleArray(ctx.surveyQuestions);
          return surveyQuestionsReducer.fn({ ...ctx, surveyQuestions });
        }),
      ),
      immediate(SURVEY_STATES.askPrelim),
    ),
    [SURVEY_STATES.askPrelim]: state(
      transition("yes", SURVEY_STATES.getPrelim),
      transition(
        "no",
        SURVEY_STATES.survey,
        reduce((ctx) => ({
          ...ctx,
          preliminaryCareersSkipped: true,
          startedOver: false,
        })),
      ),
    ),
    [SURVEY_STATES.getPrelim]: state(
      transition("answer", SURVEY_STATES.survey, preliminaryCareersReducer),
      transition("cancelPreliminaryCareer", SURVEY_STATES.askPrelim),
      startOverTransition,
    ),
    [SURVEY_STATES.survey]: state(
      immediate(SURVEY_STATES.loadingCareerMatches, guard(isSurveyComplete)),
      transition("answer", SURVEY_STATES.survey, surveyAnswersReducer),
      startOverTransition,
    ),
    [SURVEY_STATES.loadingCareerMatches]: state(
      immediate(
        SURVEY_STATES.ratingCareers,
        guard((ctx) => ctx.careerMatchesLoaded),
        careerRatingsReducer,
      ),
      dataLoadedTransition(SURVEY_STATES.loadingCareerMatches),
    ),
    [SURVEY_STATES.ratingCareers]: state(
      immediate(SURVEY_STATES.complete, guard(isRatingComplete)),
      transition(
        "answer",
        SURVEY_STATES.ratingCareers,
        careerRatingsAnswerReducer,
      ),
      startOverTransition,
    ),
    [SURVEY_STATES.complete]: state(startOverTransition),
  },
  () => initialContext,
);

export const useSurveyState = () => {
  const [state, send] = useMachine(machine);
  const {
    context: {
      preliminaryCareers,
      preliminaryCareersSkipped,
      surveyQuestions,
      careerRatings,
    },
  } = state;

  const dataLoaded = (property) => (data) =>
    send({
      type: "dataLoaded",
      property,
      data,
    });
  const userResponded = (property) => (data) =>
    send({
      type: "answer",
      property,
      data,
    });

  const progress =
    (preliminaryCareersSkipped
      ? NUM_INITIAL_QUESTIONS
      : preliminaryCareers.length) +
    surveyQuestions.reduce((cnt, q) => cnt + (isNullish(q.answer) ? 0 : 1), 0) +
    careerRatings.length;

  return {
    state,
    progress,
    startOver: () => send("startOver"),
    preliminaryCareersFetched: dataLoaded("preliminaryCareers"),
    surveyQuestionsFetched: dataLoaded("surveyQuestions"),
    careerRatingsFetched: dataLoaded("careerRatings"),
    hasPreliminaryCareer: (answer) => send(answer ? "yes" : "no"),
    cancelPreliminaryCareer: () => send("cancelPreliminaryCareer"),
    preliminaryCareerAnswered: userResponded("preliminaryCareers"),
    surveyQuestionAnswered: userResponded("surveyQuestions"),
    careerMatchesFetched: dataLoaded("careerMatches"),
    careerRatingAnswered: userResponded("careerRatings"),
  };
};

import clsx from "clsx";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import {
  Button,
  Input,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";

import { UserPasswordValidators } from "@lib/form.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./reset-password.form.scss";

export const ResetPasswordForm = ({
  className,
  isReset,
  loading,
  onResetPassword,
}) => {
  const { register, formState, handleSubmit, getValues } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const { errors, isValid, dirtyFields } = formState;

  return (
    <form
      className={clsx("reset-password-form-content", className)}
      onSubmit={handleSubmit(onResetPassword)}
    >
      <div className="login-fields-container">
        <Input
          id="password-value"
          label="Password"
          type="password"
          disabled={loading}
          {...register("password", {
            required: true,
            validate: UserPasswordValidators,
          })}
        />
        <Input
          id="confirm-password-value"
          label="Confirm Password"
          type="password"
          disabled={loading}
          {...register("passwordConfirmation", {
            required: true,
            validate: {
              matches: (v) => {
                const password = getValues("password");
                return password === v;
              },
            },
          })}
        />
      </div>
      <div className="requirements-container">
        <div className="title">Password Requirements</div>
        <div className="requirements-list">
          <ValidationItem
            label={"Minimum of 4 characters"}
            valid={!errors.password?.types.count}
            enabled={dirtyFields.password}
          />

          <ValidationItem
            label={"At least one letter"}
            valid={!errors.password?.types.hasLetter}
            enabled={dirtyFields.password}
          />

          <ValidationItem
            label={"No whitespace allowed"}
            valid={!errors.password?.types.whiteSpace}
            enabled={dirtyFields.password}
          />

          <ValidationItem
            label={"No special characters other than @ ! ? , . - _"}
            valid={!errors.password?.types.hasOnlyAllowedSpecials}
            enabled={dirtyFields.password}
          />

          <ValidationItem
            label={"Confirm your new password"}
            valid={!errors.passwordConfirmation?.types?.matches}
            enabled={dirtyFields.password && dirtyFields.passwordConfirmation}
          />
        </div>
      </div>
      <Button
        disabled={loading || !isValid}
        loader={loading}
        type="submit"
        primary
        {...buttonTracking("reset-password")}
      >
        {isReset ? "Reset" : "Set"} Password
      </Button>
    </form>
  );
};

ResetPasswordForm.displayName = "Reset Password Form";

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  isReset: PropTypes.bool,
  loading: PropTypes.bool,
  onResetPassword: PropTypes.func,
};

import clsx from "clsx";
import PropTypes from "prop-types";

import { ListItem, NO_LIST_ITEM_ICON, Toast } from "@features/ui";

import "./preliminary-career-match.toast.scss";

export const PreliminaryCareerMatchToast = ({ careers, className }) => {
  const { closeMatch, noCloseMatch } = careers.reduce(
    (acc, career) => {
      const key = career.hasCloseMatch ? "closeMatch" : "noCloseMatch";
      acc[key].push(career.title);
      return acc;
    },
    { closeMatch: [], noCloseMatch: [] },
  );

  const toastMessage = [];

  if (noCloseMatch.length > 0) {
    toastMessage.push(
      <Toast
        key="no-close-match"
        open
        type="info"
        message={
          <>
            We know you chose <strong>{noCloseMatch.join(", ")}</strong>, but we
            don&apos;t have a career for that right now. The careers listed
            below may be a good fit for you based on your interests, you can try
            them out now!
          </>
        }
      />,
    );
  }

  if (closeMatch.length > 0) {
    toastMessage.push(
      <Toast
        key="close-match"
        open
        type="info"
        message={
          <>
            We know you chose <strong>{closeMatch.join(", ")}</strong>, but we
            don&apos;t have a career for that right now. We do have similar
            careers you can try out listed below.
          </>
        }
      />,
    );
  }

  return (
    <ListItem
      className={clsx("preliminary-career-match-toast", className)}
      rightIcon={NO_LIST_ITEM_ICON}
      disabled
    >
      {toastMessage}
    </ListItem>
  );
};

PreliminaryCareerMatchToast.propTypes = {
  careers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      hasCloseMatch: PropTypes.bool.isRequired,
    }),
  ),
  className: PropTypes.string,
};

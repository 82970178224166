import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  CopyrightFooter,
  ErrorToast,
  Loader,
  SubHeading,
  Title,
} from "@features/ui";
import { HeaderImage } from "@pages/login/components/header-image";

import { ResetPasswordForm } from "./components/reset-password.form";

import { useResetPassword } from "./api/use-reset-password";
import { useValidateResetToken } from "./api/use-validate-token";

import headerImageLargeUrl from "@images/trek-logo.large.png?url";
import headerImageMediumUrl from "@images/trek-logo.medium.svg?url";

import "./index.scss";

const generalErrorMessage = "Something went wrong. Please try again.";
const renderInvalidResetLinkMessage = (isReset) => (
  <>
    Your {isReset ? "reset password" : "new account"} link has expired. Please
    visit the sign in page and press &quot;Forgot Password&quot; to try again.
  </>
);

export const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [email, token, isReset] = useMemo(
    () => [
      queryParams.get("email"),
      queryParams.get("token"),
      queryParams.get("act")?.toLowerCase() === "reset",
    ],
    [location],
  );

  const {
    mutateAsync: validateToken,
    isPending: validatingToken,
    isSuccess: isValidToken,
    isError: tokenError,
  } = useValidateResetToken();

  const {
    mutateAsync: resetPassword,
    isPending: resettingPassword,
    isError: resetPasswordError,
  } = useResetPassword();

  const onResetPassword = async ({ password }) => {
    const data = { password, token, email };
    await resetPassword({ data });
    navigate("/login", { state: { passwordReset: true } });
  };

  useEffect(() => {
    validateToken({ data: { email, token } });
  }, []);

  return (
    <div className="reset-password-page">
      <Loader
        show={validatingToken}
        text={`Validating ${isReset ? "reset password" : "new account"} token...`}
      />
      <AnimatePresence mode="popLayout">
        <HeaderImage
          className={clsx(isValidToken && "medium-size")}
          imageUrl={isValidToken ? headerImageMediumUrl : headerImageLargeUrl}
        />
        <ErrorToast
          open={tokenError || resetPasswordError}
          message={
            tokenError
              ? renderInvalidResetLinkMessage(isReset)
              : generalErrorMessage
          }
        />
        {isValidToken && (
          <>
            <div className="tx-container">
              <Title>{isReset ? "Reset" : "Set"} Password</Title>
              <SubHeading>
                Please enter a new password for your Transfr account.
              </SubHeading>
            </div>
            <ResetPasswordForm
              loading={resettingPassword || validatingToken}
              isReset={isReset}
              onResetPassword={onResetPassword}
            />
          </>
        )}
        <CopyrightFooter />
      </AnimatePresence>
    </div>
  );
};

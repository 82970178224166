import clsx from "clsx";
import PropTypes from "prop-types";
import { useMemo, useRef } from "react";

import { InterestTags, SalaryRate } from "@features/career-matches/components";
import { HiddenTag, ListItem, MatchTypeTag, SelectedTag } from "@features/ui";

import { useVisibilityCheck } from "@hooks/use-visibility-check";

import "./career-item.scss";

export const CareerItem = ({
  career,
  className,
  hidden,
  index,
  matchType,
  onClick,
  onVisibilityCheck,
  outOfFlow,
  selected,
}) => {
  const ref = useRef();

  useVisibilityCheck({ ref, initialRenderOnly: true, onVisibilityCheck });

  const { title, shortDescription, tags, hourlyEarningsMedian } = career;

  const highlightedTags = useMemo(() => {
    if (matchType || selected || hidden) {
      return (
        <div className="tags-container">
          {matchType && !selected && <MatchTypeTag type={matchType} />}
          {selected && <SelectedTag />}
          {hidden && <HiddenTag />}
        </div>
      );
    }
  }, [matchType, selected, hidden]);

  const listItem = (
    <ListItem
      ref={ref}
      index={index}
      className={clsx("career-item", className)}
      onClick={() => onClick(career, index)}
    >
      <div className="header">
        <span>{title}</span>
        {hourlyEarningsMedian && <SalaryRate value={hourlyEarningsMedian} />}
      </div>
      <div className="description">{shortDescription}</div>
      <InterestTags tags={tags} />
    </ListItem>
  );

  const component = highlightedTags ? (
    <div
      className={clsx(
        "career-item-highlighted",
        matchType && !selected && "match-type",
        selected && "selected",
        hidden && "hidden",
      )}
    >
      {listItem}
      {highlightedTags}
    </div>
  ) : (
    listItem
  );

  return outOfFlow ? <div className="out-of-flow">{component}</div> : component;
};

CareerItem.displayName = "Carrer Item";

CareerItem.propTypes = {
  career: PropTypes.object,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  index: PropTypes.number,
  matchType: PropTypes.oneOf(["exact", "related"]),
  onClick: PropTypes.func,
  onVisibilityCheck: PropTypes.func,
  outOfFlow: PropTypes.bool,
  selected: PropTypes.bool,
};

/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";

export function Stream(props) {
  const { nodes, materials } = useGLTF("/models/stream.glb");
  materials.MAT_stream_bake.opacity = 0.85;

  return (
    <group {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_stream_ground.geometry}
        material={materials.MAT_stream_ground}
        position={[3.014, 0.009, 0.183]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_btm_ground_spread.geometry}
        material={materials.PaletteMaterial002}
        position={[2.449, 0.006, -0.194]}
        scale={0.713}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_stream_path_dashed_line001.geometry}
        material={materials.PaletteMaterial003}
        position={[-0.471, 0.012, -0.56]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_med_rock001.geometry}
        material={materials["MAT_desert_stone.001"]}
        position={[-1.004, 0.081, -2.177]}
        rotation={[0, 0.005, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_front_land_chunk.geometry}
        material={materials["MAT_river_cliff-1"]}
        position={[-1.357, 0.365, -1.25]}
        rotation={[0, -0.02, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_smal_rocks.geometry}
        material={materials.MAT_desert_stone}
        position={[-1.095, 0.007, -2.298]}
        rotation={[0, 0.005, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_sml_land_scatter001.geometry}
        material={materials.PaletteMaterial004}
        position={[-0.62, 0.011, -2.107]}
        rotation={[0, 0.005, 0]}
      />
      <group position={[2.492, -0.005, 0.025]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve160_dmg.geometry}
          material={materials["MAT_river_cliff-2"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve160_dmg_1.geometry}
          material={materials["MAT_river_cliff-tunnel"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_rock_ripples-1"].geometry}
        material={materials.PaletteMaterial005}
        position={[2.57, 0.06, 0.244]}
        rotation={[0, 0, 0.006]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_stream.geometry}
        material={materials.MAT_stream_bake}
        position={[1.442, 0.309, -0.135]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_brick_row001.geometry}
        material={materials.MAT_top_brick_row}
        position={[1.513, 0.308, 0.923]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_brick_row.geometry}
        material={materials.MAT_bot_brick_row}
        position={[1.513, 0.209, 0.923]}
      />
    </group>
  );
}

useGLTF.preload("/models/stream.glb");
